import { RouteRecordRaw } from 'vue-router';

const router: Array<RouteRecordRaw> = [
  {
    path: '/admin-panel',
    name: 'admin-panel',
    redirect: '/admin-panel/dashboard',
    component: () => import('@/modules/admin/layout/Layout.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'admin-dashboard',
        component: () => import('@/modules/admin/views/DashboardAdmin.vue'),
      },
      {
          path: 'profile',
          name: 'profile',
          component: () =>
              import('@/modules/admin/views/settings/Profile.vue'),
      },      
      {
        path: 'restaurants-subscription-packages',
        name: 'admin-restaurants-subscription-packages',
        redirect: '/restaurants-subscription-packages/list',
        component: () =>
          import('@/modules/restaurants/views/subscription-packages/Main.vue'),
        children: [
          {
            path: 'list',
            name: 'admin-restaurants-subscription-packages-list',
            component: () =>
              import('@/modules/restaurants/views/subscription-packages/List.vue'),
          },
          {
            path: 'create',
            name: 'admin-restaurants-subscription-packages-create',
            component: () =>
              import('@/modules/restaurants/views/subscription-packages/Create.vue'),
          },
          {
            path: 'edit/:id',
            name: 'admin-restaurants-subscription-packages-edit',
            props: true,
            component: () =>
              import('@/modules/restaurants/views/subscription-packages/Create.vue'),
          },
        ],
      },
      {
        path: 'venders',
        name: 'admin-venders',
        redirect: '/venders/list',
        component: () => import('@/modules/admin/views/venders/MainVender.vue'),
        children: [
          {
            path: 'list',
            name: 'admin-venders-list',
            component: () =>
              import('@/modules/admin/views/venders/ListVender.vue'),
          },
          {
            path: 'create',
            name: 'admin-venders-create',
            component: () =>
              import('@/modules/admin/views/venders/CreateVender.vue'),
          },
          {
            path: 'edit/:id',
            name: 'admin-venders-edit',
            props: true,
            component: () =>
              import('@/modules/admin/views/venders/EditVender.vue'),
          },
          {
            path: 'view/:id',
            name: 'admin-venders-view',
            props: true,
            redirect: { name: 'admin-venders-view-information' },
            component: () =>
              import('@/modules/admin/views/venders/ViewVendor.vue'),
            children: [
              {
                path: 'payments',
                name: 'admin-venders-view-payments',
                component: () =>
                  import(
                    '@/modules/admin/views/venders/viewTabs/PaymentTab.vue'
                  ),
              },
              {
                path: 'referral-vendors',
                name: 'admin-venders-view-referral-vendors',
                component: () =>
                  import(
                    '@/modules/admin/views/venders/viewTabs/referralVendors.vue'
                  ),
              },
              {
                path: 'subscribe-payments',
                name: 'admin-venders-view-subscribe-payments',
                component: () =>
                  import(
                    '@/modules/admin/views/venders/viewTabs/SubscribePaymentTab.vue'
                  ),
              },
              {
                path: 'create-payments',
                name: 'admin-venders-view-create-payments',
                component: () =>
                  import(
                    '@/modules/admin/views/venders/CreatePayments.vue'
                  ),
              },
              {
                path: 'information',
                name: 'admin-venders-view-information',
                component: () =>
                  import('@/modules/admin/views/venders/viewTabs/InfoTab.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'subscriptions',
        name: 'admin-subscriptions',
        redirect: '/subscriptions/list',
        component: () => import('@/modules/admin/views/subscriptions/Main.vue'),
        children: [
          {
            path: 'list',
            name: 'admin-subscriptions-list',
            component: () =>
              import('@/modules/admin/views/subscriptions/List.vue'),
          },
          {
            path: 'view/:id',
            name: 'subscriptions-view',
            props: true,
            component: () =>
              import('@/modules/admin/views/subscriptions/View.vue'),
          },
        ],
      },
      {
        path: 'users',
        name: 'admin-users',
        redirect: '/users/list',
        component: () => import('@/modules/admin/views/users/Main.vue'),
        children: [
          {
            path: 'list',
            name: 'admin-users-list',
            component: () => import('@/modules/admin/views/users/List.vue'),
          },
          {
            path: 'create',
            name: 'admin-users-create',
            component: () => import('@/modules/admin/views/users/Create.vue'),
          },
          {
            path: 'edit/:id',
            name: 'admin-users-edit',
            props: true,
            component: () => import('@/modules/admin/views/users/Create.vue'),
          },
        ],
      },
      {
        path: 'coupons',
        name: 'admin-coupons',
        component: () => import('@/modules/admin/views/coupons/Main.vue'),
        children: [
          {
            path: 'list',
            name: 'admin-coupons-list',
            component: () => import('@/modules/admin/views/coupons/List.vue'),
          },
          {
            path: 'create',
            name: 'admin-coupons-create',
            component: () => import('@/modules/admin/views/coupons/Create.vue'),
          },
          {
            path: 'edit/:id',
            name: 'admin-coupons-edit',
            props: true,
            component: () => import('@/modules/admin/views/coupons/Create.vue'),
          },
        ],
      },
      {
        path: 'referral-code-groups',
        name: 'admin-referral-code-groups',
        redirect: '/referral-code-groups/list',
        component: () => import('@/modules/admin/views/referral-code-groups/Main.vue'),
        children: [
          {
            path: 'list',
            name: 'admin-referral-code-groups-list',
            component: () =>
              import('@/modules/admin/views/referral-code-groups/List.vue'),
          },
          {
            path: 'create',
            name: 'admin-referral-code-groups-create',
            component: () =>
              import('@/modules/admin/views/referral-code-groups/Create.vue'),
          },
          {
            path: 'edit/:id',
            name: 'admin-referral-code-groups-edit',
            props: true,
            component: () =>
              import('@/modules/admin/views/referral-code-groups/Create.vue'),
          },
          {
            path: 'view/:id',
            name: 'admin-referral-code-groups-view',
            props: true,
            redirect: { name: 'admin-referral-code-groups-view-information' },
            component: () =>
              import('@/modules/admin/views/referral-code-groups/View.vue'),
            children: [
              
            ],
          },
        ],
      },
      {
        path: 'partners',
        name: 'admin-partners',
        redirect: '/partners/list',
        component: () => import('@/modules/admin/views/partners/Main.vue'),
        children: [
          {
            path: 'list',
            name: 'admin-partners-list',
            component: () =>
              import('@/modules/admin/views/partners/List.vue'),
          },
          {
            path: 'create',
            name: 'admin-partners-create',
            component: () =>
              import('@/modules/admin/views/partners/Create.vue'),
          },
          {
            path: 'edit/:id',
            name: 'admin-partners-edit',
            props: true,
            component: () =>
              import('@/modules/admin/views/partners/Create.vue'),
          },
          {
            path: 'view/:id',
            name: 'admin-partners-view',
            props: true,
            redirect: { name: 'admin-partners-view-information' },
            component: () =>
              import('@/modules/admin/views/partners/View.vue'),
            children: [
              {
                path: 'referral-sellers',
                name: 'admin-partners-view-referralSellers',
                component: () =>
                  import(
                    '@/modules/admin/views/partners/viewTabs/referralSellers.vue'
                  ),
              },
              {
                path: 'partner-information',
                name: 'admin-partners-view-information',
                component: () =>
                  import('@/modules/admin/views/partners/viewTabs/InfoTab.vue'),
              },
              {
                path: 'partner-PaymentTab',
                name: 'admin-partners-view-PaymentTab',
                component: () =>
                  import('@/modules/admin/views/partners/viewTabs/PaymentTab.vue'),
              },
              {
                path: 'partner-payment-create',
                name: 'partner-payment-create',
                component: () =>
                  import('@/modules/admin/views/partners/CreatePayments.vue'),
              },
            ],
          },
        ],
      },

    ],
  },
  {
    path: '/admin-panel',
    redirect: {name:'admin-sign-in'},
    component: () => import('../layout/Auth.vue'),
    children: [
      {
        path: 'sign-in',
        name: 'admin-sign-in',
        component: () => import('@/modules/admin/views/auth/SignIn.vue'),
      },
      {
        path: 'reset-password',
        name: 'admin-reset-password',
        component: () => import('@/modules/admin/views/auth/ResetPassword.vue'),
      },
      {
        path: 'forget-password',
        name: 'admin-forget-password',
        component: () => import('@/modules/admin/views/auth/ForgetPassword.vue'),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () =>
      import('@/modules/common/views/authentication/Error404.vue'),
  },
  {
    // register vender
    path: '/register/vender',
    name: 'register-vender',
    component: () => import('@/modules/vender/views/registerVender.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

export default router;
